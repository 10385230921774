import { Box, Typography } from "@material-ui/core";
import { A, D, G, pipe } from "@mobily/ts-belt";
import { useAtom, useAtomValue } from "jotai";
import PropTypes from "prop-types";
import * as React from "react";
import { SignType } from "../../../domain";
import { useActiveInfluencer, useCurrentUser } from "../../../hooks";
import { currencyFormatter } from "../../../util";
import AdvancedSettings from "../AdvancedSettings";
import { useCanEdit } from "../Step2/utils";
import * as styles from "../styles";
import { STEPS } from "../utils";

const toTag = (tagString) => ({ tagString, active: true });

function ProductSummaryStep({
  productAtom,
  productSignersAtom,
  productTagsAtom,
  stateAtom,
}) {
  const currentUser = useCurrentUser();
  const activeInfluencer = useActiveInfluencer();
  const product = useAtomValue(productAtom);
  const signers = useAtomValue(productSignersAtom);
  const [productTags, setProductTags] = useAtom(productTagsAtom);
  const [, setState] = useAtom(stateAtom);
  const canEdit = useCanEdit(signers);

  const currentSigner = React.useMemo(
    () =>
      signers.find(
        (signer) => signer.influencerId === activeInfluencer.influencerId,
      ),
    [signers, activeInfluencer],
  );

  const setCurrentStep = React.useCallback(
    (step) => () => {
      setState(D.merge({ currentStep: step }));
    },
    [setState],
  );

  // This is a bit of a hack to ensure that the character and franchise tags
  // show up in the tag list when the user is editing the product, but don't
  // show up when they are adding new tags.
  React.useEffect(() => {
    if (!product.characterTagString && !product.franchiseTagString) {
      return;
    }

    let hasCharacterTag = false;
    let hasFranchiseTag = false;

    productTags.forEach((tag) => {
      if (product.characterTagString === tag.tagString) {
        hasCharacterTag = true;
      }

      if (product.franchiseTagString === tag.tagString) {
        hasFranchiseTag = true;
      }
    });

    const specialTags = [];

    if (!hasCharacterTag && product.characterTagString) {
      specialTags.push(product.characterTagString);
    }

    if (!hasFranchiseTag && product.franchiseTagString) {
      specialTags.push(product.franchiseTagString);
    }

    const uniqueSpecialTags = Array.from(new Set(specialTags)).map(toTag);
    const tags = [...productTags, ...uniqueSpecialTags];

    if (tags.length === productTags.length) {
      return;
    }

    setProductTags(tags);
  }, [
    productTags,
    product.characterTagString,
    product.franchiseTagString,
    setProductTags,
  ]);

  const canSeeOtherSigners = currentUser.isAdmin;

  return (
    <>
      <Box
        alignItems="center"
        display="flex"
        flexWrap="wrap"
        justifyContent="center"
        mb={1}
      >
        {Boolean(product.imageUrl) && (
          <img
            style={{ maxWidth: "100%", objectFit: "contain" }}
            height={256}
            src={product.imageUrl}
            alt={product.name}
          />
        )}

        {!product.imageUrl && (
          <Box minHeight="256">
            <Typography variant="body2" color="error">
              No image
            </Typography>
          </Box>
        )}

        <Box
          alignItems="center"
          display="flex"
          justifyContent="center"
          mt={1}
          width="100%"
        >
          <styles.TextButton
            disabled={!canEdit}
            onClick={setCurrentStep(STEPS.PRODUCT_DETAILS)}
          >
            Edit Image
          </styles.TextButton>
        </Box>
      </Box>

      <Box display="flex" flexDirection="column" gridGap="4px" mb={3}>
        <Box
          width="100%"
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <div>
            <styles.Label>Name</styles.Label>

            <Typography
              variant="body2"
              color={!product.name ? "error" : "textSecondary"}
            >
              {product.name || "No name"}
            </Typography>
          </div>

          <styles.TextButton
            disabled={!canEdit}
            onClick={setCurrentStep(STEPS.PRODUCT_DETAILS)}
          >
            Edit Details
          </styles.TextButton>
        </Box>

        <div>
          <styles.Label>Description</styles.Label>

          <Typography variant="body2" color="textSecondary">
            {product.subtext || "No description"}
          </Typography>
        </div>

        <div>
          <styles.Label>Quantity</styles.Label>

          <Typography
            variant="body2"
            color={
              product.stock !== null && product.stock < 0
                ? "error"
                : "textSecondary"
            }
          >
            {product.stock ?? "Unlimited"}
          </Typography>
        </div>

        {!activeInfluencer?.isGroupShop && (
          <div>
            <styles.Label>Shop Position</styles.Label>

            <Typography variant="body2" color="textSecondary">
              {currentSigner?.position ?? "No position"}
            </Typography>
          </div>
        )}
      </Box>

      <Box
        width="100%"
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
        mb={3}
      >
        <div>
          <styles.Label>Type</styles.Label>

          <Typography variant="body2" color="textSecondary">
            {SignType.toLabel(product.signedType)} (
            {product.productType ?? "Other"})
          </Typography>
        </div>

        <styles.TextButton
          disabled={!canEdit}
          onClick={setCurrentStep(STEPS.CREATE_PRODUCT)}
        >
          Edit Type
        </styles.TextButton>
      </Box>

      {G.isArray(signers) && A.isNotEmpty(signers) && (
        <Signers
          activeInfluencer={activeInfluencer}
          canEdit={canEdit}
          canSeeOtherSigners={canSeeOtherSigners}
          currentUser={currentUser}
          setCurrentStep={setCurrentStep}
          signers={signers}
        />
      )}

      <Box
        width="100%"
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
        mb={3}
      >
        <div>
          <styles.Label>Tags</styles.Label>

          <Box display="flex" gridGap="8px" flexWrap="wrap">
            {A.isEmpty(productTags) && (
              <Typography variant="body2" color="textSecondary">
                No tags.
              </Typography>
            )}

            {A.isNotEmpty(productTags) &&
              productTags.map(({ tagString }) => (
                <styles.Chip size="small" label={tagString} key={tagString} />
              ))}
          </Box>
        </div>

        <styles.TextButton onClick={setCurrentStep(STEPS.INCREASE_EXPOSURE)}>
          Edit Tags
        </styles.TextButton>
      </Box>

      {canEdit && (
        <AdvancedSettings
          productAtom={productAtom}
          productSignersAtom={productSignersAtom}
        />
      )}
    </>
  );
}

ProductSummaryStep.propTypes = {
  productAtom: PropTypes.object.isRequired,
  productCharactersAtom: PropTypes.object.isRequired,
  productSignersAtom: PropTypes.object.isRequired,
  productTagsAtom: PropTypes.object.isRequired,
  stateAtom: PropTypes.object.isRequired,
};

export default {
  Component: ProductSummaryStep,
  title: "Product Summary",
};

function Signers({
  activeInfluencer,
  canEdit,
  canSeeOtherSigners,
  currentUser,
  setCurrentStep,
  signers,
}) {
  return (
    <Box display="flex" flexDirection="column" gridGap="4px" mb={3}>
      {pipe(
        signers,
        A.filter(
          (signer) =>
            canSeeOtherSigners ||
            signer.influencerId === activeInfluencer.influencerId,
        ),
        A.map((signer) => (
          <Box key={signer.influencerId} display="flex" flexDirection="column">
            <Box
              display="flex"
              alignItems="flex-start"
              justifyContent="space-between"
              width="100%"
            >
              <div>
                {canSeeOtherSigners && (
                  <styles.Label>
                    How much {signer.name} will make per sale
                  </styles.Label>
                )}

                {!canSeeOtherSigners && (
                  <styles.Span>
                    <styles.Label>Pricing</styles.Label> (How much
                    {signer.influencerId === activeInfluencer.influencerId &&
                    currentUser.email === activeInfluencer.ownerEmail
                      ? " you "
                      : ` ${signer.name} `}
                    will make per sale)
                  </styles.Span>
                )}

                <Typography variant="body2" color="textSecondary">
                  {currencyFormatter.format(signer.splitAmount / 100)} (Talent
                  Split)
                </Typography>
              </div>

              <styles.TextButton
                disabled={
                  !canEdit &&
                  signer.influencerId !== activeInfluencer.influencerId
                }
                onClick={setCurrentStep(STEPS.PRODUCT_DETAILS)}
              >
                Edit Pricing
              </styles.TextButton>
            </Box>
          </Box>
        )),
      )}
    </Box>
  );
}

Signers.propTypes = {
  activeInfluencer: PropTypes.object.isRequired,
  canEdit: PropTypes.bool.isRequired,
  canSeeOtherSigners: PropTypes.bool.isRequired,
  currentUser: PropTypes.object.isRequired,
  setCurrentStep: PropTypes.func.isRequired,
  signers: PropTypes.array.isRequired,
};
